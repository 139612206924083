<template>

  <div class="sell">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <div class="box">
            <div class="flex_between">
                <h3 class="explain">总押金 (元)</h3>
                <h3 class="number">{{totalDeposit}}</h3>
            </div>
            <!-- <div class="flex_between">
                <div class="explain">总占用押金（元）</div>
                <div class="number">{{(totalDeposit - usableDeposi).toFixed(2) }}</div>
            </div>            
            <div class="flex_between ">
                <div class="explain">可退押金（元）</div>
                <div class="number" >{{usableDeposi}}</div>
            </div>  -->
            <div class="flex_between  border">
                <div class="explain">黄金现价(元/克)</div>
                <div class="number" :style="{color:this.redColor?'#ff2d2e':'#02790D'}">{{pricehj}}</div>
            </div>            
            <div class="flex_between marginTop">
                <div class="explain ">订单数量（单）/订单总重量（克）</div>
                <div class="number">{{checkList.length}}/{{orderWeight}}</div>
            </div>                      
             <div class="flex_between">
                <div class="explain">订单均价（元/克）</div>
                <div class="number">{{averagePrice}}</div>
            </div>            
            <div class="flex_between">
                <div class="explain">物料总差价（元）</div>
                <div class="number">{{PriceDifference.toFixed(2)}}</div>
            </div>            
            <div class="flex_between">
                <div class="explain fontSize">总保管费（元） </div>
                <div class="number fontSize">{{CustodyFee}}</div>
            </div>            
            <div class="flex_between">
                <div class="explain  fontSize">占用总押金(元)
                   <el-tooltip class="item" effect="dark" content="结算后将释放回可用押金" placement="top-start">
                                    <van-icon name="question-o" color="#FF2D2E"  />
                   </el-tooltip>    
               </div>
                <div class="number fontSize">{{occupyDeposi}}</div>
            </div>            
            <div class="flex_between  ">
                <div class="explain  JSFontSize fontWeight">结算金额（元）    
                  <el-tooltip class="item" effect="dark" content="将累加到押金余额里" placement="top-start">
                                    <van-icon name="question-o" color="#FF2D2E"  />
                   </el-tooltip> </div>
                <div class="number fontSize">{{settlementTotalDeposit.toFixed(2)}}</div>
            </div>

        </div>
         <van-list
               v-model="loading"
               :finished="finished"
               finished-text="没有更多了"
               @load="onLoad"
            >
        <div class="flex" v-for="(item,index) in getViolateList" :key="index">
            <div class="line"></div>
            <div class="orderInfo" >
              <div class="flex_between borderBtm">
                    <div class="flex">
                            <h2>{{item.goodName}}</h2>  
                             <div class="icon">
                                 卖料</div>  
                    </div> 
                    <div class="flex">
                        <div class="time">{{item.created | formatDate_}}</div>
                        <input type="checkbox" class="checkBox" name="checkBoxTest" :value="item"  v-model="checkList" @change="checkBox">
                    </div>   
              </div>  
               <div class="flex_between marginTop">
                    <div class="explain">定价重量(克)/单价(元/克)</div>
                    <div class="number">{{item.weight}}/{{item.price}}</div>
               </div>              
            
               <div class="flex_between marginTop">
                    <div class="explain">押金（元）</div>
                    <div class="number">{{item.earnestMoney}}</div>
               </div>               
               <div class="flex_between marginTop">
                    <div class="explain">保管费（元）</div>
                    <div class="number">{{item.storageFee == 0?0:-(item.storageFee)}}</div>
               </div>               
               <div class="flex_between marginTop">
                    <div class="explain">物料差价（元）</div>
                    <div class="number">{{item.difference}}</div>
               </div>
               <div class="flex_between marginTop">
                 <div class="explain">押金调整</div>
                 <div class="number" @click="openShowPop(item.orderId,item)"><van-icon name="arrow" /></div>
               </div>
              <div class="flex_between marginTop">
                <div class="explain">押金比例(%)</div>
                <div class="number">{{item.depositProportion}}</div>
              </div>
            </div> 
        </div>
         </van-list>
    </van-pull-refresh>
         <!-- <div class="btn" @click="breakjieSuan">定价料单-违约结算</div> -->
        <!-- 定金弹窗 -->
        <showPop :showPop.sync="showPop"  ref="pop"/>
        <!-- 违约结算弹窗 -->
        <van-dialog v-model="showbreak" :show-confirm-button="false" get-container="#app" >
          <div class="title">违约结算</div>
          <div class="centerbox_dialog">
            
            <div class="flex_between col999">  <div>物料差价 (元)</div>  <div>{{PriceDifference.toFixed(2)}}</div> </div>
            <div class="flex_between col999">   <div>保管费（元）</div>  <div>{{CustodyFee}}</div></div>
            <!-- <div class="flex_between col999">   <div>原押金（元）</div>  <div>{{occupyDeposi}}</div></div> -->
            <div class="flex_between break">   <div>结算金额（元）</div>  <div>{{settlementTotalDeposit.toFixed(2)}}</div></div>
          </div>
          <!-- <div class="breakexplain">
                <p>{{violateTitle}}</p>
          </div> -->
          <div class="flex_between popbtn">
            <div class="gocer closebtn" @click="close" >取消</div>
            <div class="gocer confirmbtn" @click="commit">确定</div>
          </div>
        </van-dialog>
        <van-dialog v-model="ceringModel" class="ceringModel" :show-confirm-button="false" get-container="#app">
            <div class="title">提示</div>
            <div class="centerbox_dialog">{{stopTrading}}</div>
            <div class="confirm" @click="ceringModel=false">我知道了</div>
        </van-dialog>

    </div>

</template>

<script>
import showPop from '@/components/depositAdjust.vue'
import *as api from'@/utils/api'
import Stomp from 'stompjs'

export default {
    components:{
        showPop
    },
    data() {
        return {
            showPop:false,
            showbreak:false,
            ceringModel:false,
            redColor:true, //判断现价涨还是跌 涨红色 跌绿色
            violateTitle:'',
            getViolateList:[],
            // 复选框选中数据
            checkList:[],
            // 订单id集合
            orderIdList:[],
            totalDeposit:0, //总押金
            occupyDeposi:0, // 占用押金
            usableDeposi:0, //可用押金
            orderQuantity:0,//订单数量
            orderWeight:0,//订单重量
            averagePrice:0,//均价
            PriceDifference:0,//总差价
            CustodyFee:0,//保管费
            settlementTotalDeposit:0,//结算后总定金
            client: {},
            // 所有商品数据
            allpro:[],
            // 首次加载的数据
            initDatalist: [],
            // 是否开盘
            trade_: false,
            // 提交时间
            postTime:'',
            firstloadData: true,
            differenceData:'',
            pricehj:0,
            priceboj:0,
            pricebaj:0,
            pricebaiy:0,
            initCity:{
                huangjin:'huangjin9999',
                baiyin:'baiyin9999',
                bajin:"bajin9996",
                bojin:"bojin9996"
            },
            refreshing:false,
            loading: false,
            finished: false,
            page:0,
            size:3,
            marginCall: 80,
            returnDeposit:200,
            stopTrading:'',//停盘说明
        }
    },
    created() {
        
        
    },
    mounted(){
            this.connect()
      this.getDepositRepair()
            this.DefaultExplain()
            // this.getViolateData()
            // this.onRefresh()
    },
watch:{
        allpro:function(){
          if(this.initCity != ''){
             this.updatearr(this.initCity)
             this.ProcessingData(this.getViolateList)
              let weight = 0
              let junjia = 0
              let chajia = 0
              let baoguan= 0
              let totalZongdj = 0
              let yajin =0
        this.checkList.forEach(item=>{
            //  订单重量
             weight+= item.weight
            //  订单均价
            junjia +=  +item.price * item.weight
            //物料差价
            chajia += +item.difference
            // 保管费
            baoguan += -(item.storageFee)
            // 结算总金额
            totalZongdj +=(+item.difference) + -(item.storageFee) 
            // totalZongdj += (item.earnestMoney + (+item.difference) +baoguan )
            // 订单占用押金
            yajin += item.earnestMoney
            this.orderWeight = weight
            this.averagePrice = (junjia/weight).toFixed(2)
            this.PriceDifference = chajia
            this.CustodyFee = baoguan
            this.settlementTotalDeposit = totalZongdj
            this.occupyDeposi = yajin
            // console.log(item);
        })
            }
        },
        checkList:function(){
            if (this.checkList.length == 0) {
                this.orderWeight = 0
                this.averagePrice = 0
                this.CustodyFee = 0
                this.PriceDifference = 0
                this.settlementTotalDeposit = 0
                this.occupyDeposi = 0
            }
        },
        pricehj(newVal,oldVal){
              oldVal>newVal?this.redColor = false:this.redColor = true
        }
},
methods:{
 async   onLoad(){
            // console.log('page2',this.page);
            let res = await api.getViolateData({type:'SELL',page:this.page,size:this.size})
             this.page = res.violateOrderList.number +1
              // console.log('page3',res.violateOrderList.number);

              this.getViolateList.push(...res.violateOrderList.content)
              this.totalDeposit = res.totalEarnestMoney
              this.usableDeposi  = res.FreeEarnestMoney
             this.loading = false
             if (res.violateOrderList.last == true) {
                this.finished = true
                this.ProcessingData(this.getViolateList)
             }
             
    },
async  onRefresh(){
        // console.log('page0',this.page);
        this.finished = false
        let res = await api.getViolateData({type:'SELL',page:0,size:this.size})

        this.page = res.violateOrderList.number +1
        // console.log('page1',res.violateOrderList.number);
        this.getViolateList = res.violateOrderList.content
        this.totalDeposit = res.totalEarnestMoney
        this.usableDeposi  = res.FreeEarnestMoney
        this.refreshing = false 
        this.loading = true
        this.onLoad()
},
      // 取消按钮事件
    close(){
        this.showbreak = false
        this.orderIdList = []
    },
      // 确认按钮点击事件
async  commit(){
        this.checkList.forEach(item=>{

          this.orderIdList.push(item.orderId)
        })


        this.showbreak = false
        let res = await api.confirmViolateJieSuan({time:this.postTime,orderList:this.orderIdList,type:'SELL'})
        // 提交成功
        if (res.msg == "success") {
        this.orderIdList = []
        this.$router.push({name:'successpage',query:{type:'Default'}})
        }
        // console.log(res);

    },
        // 复选框选中事件
    checkBox(e){
        // console.log('checkList',this.checkList);
        // console.log('checked',e.target.checked);
        let weight = 0
        let junjia = 0
        let chajia = 0
        let baoguan= 0
        let totalZongdj = 0
        let yajin =0
        this.checkList.forEach(item=>{
            //  订单重量
             weight+= item.weight
            //  订单均价
            junjia +=  +item.price * item.weight
            //物料差价
            chajia += +item.difference
            // 保管费
            baoguan += -(item.storageFee)
            // 总定金
            totalZongdj += (+item.difference) + -(item.storageFee) 
            // 订单占用押金
            yajin += item.earnestMoney
            this.orderWeight = weight
            this.averagePrice = (junjia/weight).toFixed(2)
            this.PriceDifference = chajia
            this.CustodyFee = baoguan
            this.settlementTotalDeposit = totalZongdj
            this.occupyDeposi = yajin
            // console.log(item);
        })
    },
    // 获取违约数据
async  getViolateData(){
    this.$nextTick(async()=>{
        let res = await api.getViolateData({type:'SELL'})
        // console.log(res);
        this.getViolateList = res.violateOrderList.content
        this.totalDeposit = res.totalEarnestMoney
        this.usableDeposi  = res.FreeEarnestMoney

        this.ProcessingData(this.getViolateList)
    })
    },
  async getDepositRepair(){
    const res = await api.getDepositRepair()
    this.marginCall = res.marginCall
    this.returnDeposit = res.returnDeposit
  },
        // 数据处理
    ProcessingData(ViolateList){
        ViolateList.forEach((item,index)=>{

        // 物料差价
        let difference = 0
        // 需补定金
        let reqDeposit = 0
        // 固定定金
        let FixedDeposit = 0
        // 获取政策数据 1g黄金定金多少
        let userinfo = JSON.parse(localStorage.getItem('userinfo'))
        // let policy = new Date(item.created*1000) > new Date("2022-11-26 12:00:00".replace(/-/g,'/')) ? userinfo.depositDetail.curSell.policy : 10
          let policy = userinfo.depositDetail.curSell.policy
            if(item.goodName == "黄金金条9999" || item.goodName == "黄金实物"){
                   difference = (( item.price - this.pricehj)*item.weight).toFixed(2)
                //    固定定金
                   FixedDeposit =  item.weight * policy
                    // reqDeposit = ((item.price - this.pricehj) - [(item.earnestMoney - FixedDeposit)/item.weight]) <=0?0:((item.price - this.pricehj) - [(item.earnestMoney - FixedDeposit)/item.weight]).toFixed(2)
                    //  console.log('difference',difference);
                     this.$set(item,'difference',difference)
                     this.$set(item,'reqDeposit',reqDeposit)
                     this.$set(item,'depositProportion', ((((item.earnestMoney + parseFloat(difference) - item.storageFee) / item.weight / policy)*100).toFixed(2)))
                    // this.$set可以手动将对象的属性添加到vue检测数据更新的机制中t

            }            
            else if(item.goodName == "白银实物"){
                   difference = (( item.price - this.pricebaiy)*item.weight).toFixed(2)
                //    固定定金
                   FixedDeposit =  item.weight * policy
                    reqDeposit = ((item.price - this.pricebaiy) - [(item.earnestMoney - FixedDeposit)/item.weight]) <=0?0:((this.pricebaiy - item.price) - [(item.earnestMoney - FixedDeposit)/item.weight]).toFixed(2)
                      this.$set(item,'difference',difference)
                     this.$set(item,'reqDeposit',reqDeposit)
                     this.$set(item,'depositProportion', ((((item.earnestMoney + parseFloat(difference) - item.storageFee) / item.weight / policy)*100).toFixed(2)))
                    // this.$set可以手动将对象的属性添加到vue检测数据更新的机制中t

            }            
            else if(item.goodName == "铂金实物"){
                     difference = (( item.price - this.priceboj)*item.weight).toFixed(2)
                //    固定定金
                   FixedDeposit =  item.weight * policy
                    reqDeposit = ((item.price - this.priceboj) - [(item.earnestMoney - FixedDeposit)/item.weight]) <=0?0:((this.pricehj - item.price) - [(item.earnestMoney - FixedDeposit)/item.weight]).toFixed(2)
                      this.$set(item,'difference',difference)
                     this.$set(item,'reqDeposit',reqDeposit)
                     this.$set(item,'depositProportion', ((((item.earnestMoney + parseFloat(difference) - item.storageFee) / item.weight / policy)*100).toFixed(2)))
                    // this.$set可以手动将对象的属性添加到vue检测数据更新的机制中t

            }        
            else if(item.goodName == "钯金实物"){
                     difference = (( item.price - this.pricebaj)*item.weight).toFixed(2)
                //    固定定金
                   FixedDeposit =  item.weight * policy
                    reqDeposit = ((item.price - this.pricebaj) - [(item.earnestMoney - FixedDeposit)/item.weight]) <=0?0:((this.pricebaj - item.price) - [(item.earnestMoney - FixedDeposit)/item.weight]).toFixed(2)
                      this.$set(item,'difference',difference)
                     this.$set(item,'reqDeposit',reqDeposit)
                     this.$set(item,'depositProportion', ((((item.earnestMoney + parseFloat(difference) - item.storageFee) / item.weight / policy)*100).toFixed(2)))
                    // this.$set可以手动将对象的属性添加到vue检测数据更新的机制中t

            }
        })
    },
    // 违约说明
async  DefaultExplain(){
        // let res = await api.getDefaultExplain({
        //     title:'violateTips'
        // })
        // // console.log(res);
        // this.violateTitle = res.content
        const stopTrading = await  api.getDefaultExplain({title:'stopTradingDescription'})
        this.stopTrading = stopTrading.content
    },
    // 违约结算按钮事件
    breakjieSuan(){
      if (!this.trade_) {
        this.ceringModel = true
        // console.log('未开盘');
        return false
      }
        if (this.checkList.length == 0) {
            this.$toast('请选择订单')
            return false
        }
        this.showbreak = true
    },
    // 定金按钮事件
    openShowPop(id,item){
          const userInfo = JSON.parse(localStorage.getItem('userinfo'))
    //  政策押金1g/元
        console.log(item.difference)
        const needDeposit = +item.earnestMoney - +(+item.weight * userInfo.depositDetail.curSell.policy)  + +item.difference
      if (item.depositProportion > this.returnDeposit) {
        if(item.earnestMoney > item.weight * userInfo.depositDetail.curSell.policy){
          this.$refs.pop.openDialog(id,item,'SELL',true)
        }
        else{
          // 用户没有补过押金或者补过已退不支持退押金
          return this.$toast('暂无可退押金'), false
        }
      }
          else if (item.depositProportion>=this.marginCall && item.depositProportion<=this.returnDeposit) {
               
                return this.$toast('暂无需补押金'), false
          }
          else{

            this.$refs.pop.openDialog(id,item,'SELL',false)
          }
        // this.$refs.pop.openDialog(id,item,'SELL',false)
    },
    unzip(b64Data) {
      let strData = atob(b64Data);
      const charData = strData.split("").map(function(x) {
        return x.charCodeAt(0);
      });
      const binData = new Uint8Array(charData);
      const data = pako.inflate(binData);
      strData = String.fromCharCode.apply(null, new Uint16Array(data));
      return decodeURIComponent(escape(strData));
    },
    onConnected() {
      const topic = "/price/all";
      this.client.subscribe(topic, this.responseCallback, this.onFailed);
    },
    connect() {
       this.client = Stomp.over(new WebSocket(process.env.VUE_APP_WS));
      // ---初始化mqtt客户端，并连接mqtt服务
      const headers = {
        login: "username",
        passcode: "password"
      };
      this.client.connect(headers, this.onConnected, this.onFailed);
      this.client.debug = null;
    },
    onFailed(frame) {
      console.log("错误: " + frame);
          if (!this.client.connected) {
        setTimeout(() => {
          this.connect();
        }, 1000);
      }
    },
    deepMerge(...objs) {
      const than = this
      const result = Object.create(null)
      objs.forEach(obj => {
        if (obj) {
          Object.keys(obj).forEach(key => {
            const val = obj[key]
            if (than.isPlainObject(val)) {
              // 递归
              if (than.isPlainObject(result[key])) {
                result[key] = than.deepMerge(result[key], val)
              } else {
                result[key] = than.deepMerge(val)
              }
            } else {
                //  数组也要重新赋值  不然依然会引用到其他的
              if (Array.isArray(val)) {
                result[key] = [...val]
              } else {
                result[key] = val
              }
            }
          })
        }
      })
      return result
    },
    isPlainObject(val) {
      const toString = Object.prototype.toString
      return toString.call(val) === '[object Object]'
    },
    responseCallback (frame) {
      this.allpro = []
      const resbody = eval('['+this.unzip(frame.body)+']')[0]
      const parse_res = JSON.parse(JSON.stringify(resbody));
      this.trade_ = resbody.trade;
      this.postTime = resbody.time
      // console.log('postTime1',this.postTime);
      if(this.firstloadData){
        this.initDatalist = resbody
        this.firstloadData = false
        for(const i in this.initDatalist){
          if(this.initDatalist[i]['prices'] != undefined){
            
            this.allpro.push(this.initDatalist[i]['prices'])
          }
        }
      }else{
        this.initDatalist = this.deepMerge(this.initDatalist,parse_res)
        for(const i in this.initDatalist){
          if(this.initDatalist[i]['prices'] != undefined){
            this.allpro.push(this.initDatalist[i]['prices'])
          }
        }
      }
    },
    // 价格变动更新视图
    pricenew(obj){
      this.allpro.find((x)=>{
        if(x[obj.huangjin]){
          this.pricehj = x[obj.huangjin]['xiaoshou']
        }        
        if(x[obj.baiyin]){
          this.pricebaiy = x[obj.baiyin]['xiaoshou']
        }        
        if(x[obj.bajin]){
          this.pricebaj = x[obj.bajin]['xiaoshou']
        }        
        if(x[obj.bojin]){
          this.priceboj = x[obj.bojin]['xiaoshou']
        }
      })
    },
    // 更新数组
    updatearr(obj){
            this.pricenew(obj)
    },
    }
}
</script>

<style lang="scss" scoped>
::v-deep{
  .van-list__finished-text{
    font-size: 24px;
  }
}
.sell{
    background-color: #f5f5f5;
    padding: 10px 30px 100px;
    .box{
        padding: 30px;
        background-color: #fff;
        border-radius: 8px 8px 8px 8px;
        margin-top: 30px;
        h3{
            font-size:30px;
            font-weight: 500;
        }
        .van-icon{
          color: #FF2D2E;
        }
        
    }
    .btn{
        width: 100%;
        height: 84px;
        border-radius: 50px;
        background-color:#FF2D2E ;
        color: #fff;
        text-align: center;
        line-height: 84px;
        font-size: 32px;
    }
}
.fontWeight{
font-weight: 500;
}
.line{
    width: 8px;
    height: 510px;
    border-radius: 8px 0px 0px 8px;
    background-color: #CC0E0F;
    margin-top: 30px;
}
.orderInfo{
        width: 98%;
        padding: 10px 30px 0px;
        background-color: #fff;
        border-radius: 8px 8px 8px 8px;
        margin-top: 30px;
    h2{
        font-size: 38px;
        font-weight: 400;
        color: #242424;
        line-height: 80px;
    }
    .time{
        line-height: 80px;
        color: #242424;
        font-size: 24px;
        margin-right: 30px;
    }
    .explain{
        color: #999999;
    }
}
.explain{
    letter-spacing: 2px;
    color: #666666;
    font-size: 28px;
}
.number{
    font-size: 28px;
}
.flex{
    display: flex;
    h2{
        color: #242424;
    }
}
.flex_between{
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
}
.marginTop{
    margin-top: 4px;
}
.borderBtm{
    border-bottom: 1px solid #E9E9E9;
    padding-bottom: 12px;
}
.icon{
    width: 60px;
    height: 32px;
    font-size: 20px;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
    line-height: 32px;
    border-radius: 4px 4px 4px 4px;
    background: #CC0E0F;
}
.bg{
  width: 100%;
  height: 100%;
}
.van-icon{
    top: 2px;
    color: #999999;
}
input[type="checkbox"]{
    width:22px;
    height:22px;
    display: inline-block;
    text-align: center;
    vertical-align: middle; 
    border-radius: 50%;
    position: relative;
    margin-right: 20px;
    top: 26px;
    }
input[type="checkbox"]::before{
    content: "";
    position: absolute;
    top: -6px;
    left: -6px;
    background: #fff;
    width:36px;
    height: 36px;
    border: 1px solid #E9E9E9;
    background-color: #f5f5f5;
    border-radius: 50%;}

input[type="checkbox"]:checked::before{
    content: "\2713";
    position: absolute;
    top: -6px;
    left: -6px;
    width:36px;
    height: 36px;
    border-radius: 50%;
    color:#fff;
    background-color: #ff2d2e;
    line-height: 36px;
    font-size: 28px;
    font-weight: 400;
    }
.van-dialog{
    width: 90%;
    padding: 0 40px 30px;
    .title{
        color: #242424;
    }
    .van-field{
        font-size: 28px;
    }
  }
.popbtn{
  margin-top: 25px;
  div{
    margin-top: 5px!important;
    border-top: none!important;
    width: 260px;
    text-align: center;
    border-radius: 50px;
  }
  
}
.title{
  font-size: 32px;
  text-align: center;
  margin: 25px 0;
  color: #242424;
}
.break{
    padding: 4px 0;
    color: #242424;
}
.col999{
    padding: 4px 0;
  color: #999999;
}
.gocer{
  font-size: 32px;
  padding: 14px 0;
  margin-top: 30px;
  color:#fff;
  text-align: center;
}
.centerbox_dialog{
  text-align: left;
  // padding: 0 10px;
  font-size: 28px;
}
.closebtn{
    background-color: #D1D1D1;
}
.confirmbtn{
    background-color:#FF2D2E ;
}
.breakexplain{
    color: #999;
    font-size: 28px;
    margin: 40px 0;
    p{
        margin-bottom: 10px;
    }
}
.border{
    padding-bottom: 26px;
    border-bottom: 1px solid #E9E9E9;
}
.fontSize{
  font-size: 28px;
  color: #242424;
}
 .confirm{
    text-align: center;
    height: 40px;
    color: #FF2D2E;
    margin-top: 40px;
    font-size: 28px;
    line-height: 40px;
  }
.title{
  font-size: 36px;
  text-align: center;
  margin: 25px 0;
  color: #242424;
}
.ceringModel{
    width: 74%;
    .centerbox_dialog{
      padding: 0 0 30px !important;
      border-bottom: 1px solid #E9E9E9;
    }
    .confirm{
      margin: 30px 0  0;
    }
}
.centerbox_dialog{
  text-align: left;
  // padding: 0 50px;
  color: #666666;
  // padding: 0 10px;
  font-size: 30px;
}
.JSFontSize{
  font-size: 32px;
  font-weight: 500;
}
</style>